import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@mui/material/Button/Button.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@mui/material/Card/Card.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@mui/material/CardActions/CardActions.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@mui/material/CardContent/CardContent.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@mui/material/CardHeader/CardHeader.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@mui/material/Divider/Divider.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@mui/material/node/Grid2/Grid2.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@mui/material/Paper/Paper.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@mui/material/Stack/Stack.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@mui/material/Typography/Typography.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js")